.rcw-picker-btn {
  display: none;
}

.rcw-sender {
  justify-content: space-between;
  align-items: center;
}

.rcw-new-message {
  width: calc(100% - 50px);
}
.rcw-launcher {
  background-color: #0d2375;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rcw-conversation-container .rcw-header {
  background-color: #0d2375;
  padding: 10px 0 15px;
}
.rcw-client .rcw-message-text {
  color: white;
  background-color: #0d2375;
}

.rcw-conversation-container .rcw-close-button {
  background-color: #0d2375;
}

.rcw-error {
  color: #ff0022;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .rcw-launcher {
    left: 0;
  }
  .rcw-hide-sm {
    display: none;
  }
}
